import axios from "axios";
// const urlHeader:string = "http://192.168.31.32:10086"
// const urlHeader:string = "http://192.168.31.129:55555" // 尊贞本地
// const urlHeader:string = "http://yun-manage-api.yunicu.cn" // 测试
const urlHeader:string = "https://yun-new-admin-api.yunicu.com" // 正式
let byId:any = undefined
export function setById (value:any) {
    byId = value
}
export function get(url:String,data?:Object){
    return axios.get(urlHeader+url,{
            params:data
            }
        ).then(res => {
            return res.data;
        }, err => {
            console.log(err);
        })
}

export function post(url:String,data?:Object){
    return axios.post(urlHeader+url,data,{headers: {"byId": byId}})
        .then(res => {
            return res.data;
        }, err => {
            console.log(err);
        })
}
export function postce(url:String,data?:Object){
    return axios.post(urlHeader+url,data,{headers: {"byId": byId}})
        .then(res => {
            return res.data;
        }, err => {
            console.log(err);
        })
}

export function put(url:String,data:Object){
    return axios.put(urlHeader+url,data,{headers:{'byId': byId}})
        .then(res => {
            return res.data;
        }, err => {
            console.log(err);
        })
}


export function del(url:String,data?:Object){
    return axios.delete(urlHeader+url,{
        headers:{"byId":byId},
            params:data
        }
    ).then(res => {
        return res.data;
    }, err => {
        console.log(err);
    })
}

// axios.interceptors.response.use(res => {
//     console.log(1)
//     return  res
// }, err => {
//     console.log(2)
//     console.log(err);
//     console.log(window.location.pathname)
// })
