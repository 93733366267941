import { createRouter,createWebHistory} from 'vue-router'
import {setById} from "@/service";
import {useIndexStore} from "@/stores";
import {Dialog} from "vant";

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/case',
    component: () => import('@/Index.vue'),
    children:[
      {
        path: '/test-web-info',
        name: 'testWebInfo',
        component: () => import('@/views/test/TestWebInfo.vue')
      },
      //  拦截4040
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/NotFound.vue')
      },
      /**
       * 病例主页
       */
      {
        path:'/case',
        name:'Case',
        component: ()=>import('@/views/case/Index')
      },
      /**
       * 病例先详情
       */
      {
        path: '/case/detail',
        name:'CaseDetail',
        component: ()=>import('@/views/case/CasePatientDetail')
      },
      /**
       * 选择病例模板列表
       */
      {
        path: '/case/templates',
        name:`CaseTemplate`,
        component: ()=>import('@/views/case/CaseTemplates')
      },
      /**
       * 新建病例患者
       */
      {
        path: '/case/createCasePatient',
        name:`CreateCasePatient`,
        component: ()=>import('@/views/case/CreateCasePatient')
      },
      /**
       * 标签管理
       */
      {
        path: '/case/LabelManagement',
        name:`LabelManagement`,
        component: ()=>import('@/views/case/LabelManagement')
      },
      /**
       * 新建病程
       */
      {
        path: '/case/NewCourseOfDisease',
        name:`NewCourseOfDisease`,
        component: ()=>import('@/views/case/NewCourseOfDisease')
      },

    ]
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let status = true // 设置闸门，默认开启
  if (to.path === '/case') {
    if (to.query.memberId) { // 在主页判断是否携带用户id
      window.localStorage.setItem('memberId', to.query.memberId) // 将用户id保存到页面
      setById(to.query.memberId)
    }
  }else {
    let mid = window.localStorage.getItem('memberId')
    if (mid !== '' && mid) { // 在其他页面判断当前页面是否保存用户id
      setById(mid)
    }else { // 没有保存用户id，闸门关闭
      status = false
    }
  }
  if (status) { // 判断闸门是否开启
    if (from.path === '/case/NewCourseOfDisease' && to.path === '/case/detail' && useIndexStore().editType === false && useIndexStore().title === '编辑病程') { // 患者编辑页面返回拦截
       window.history.pushState({target: from.fullPath, random: Math.random()}, "", location.href);
          Dialog.confirm({
            title: '提示',
            message:
                '是否放弃此次修改？',
            closeOnPopstate: false,
          }).then(() => {
              next()
            // history.back()
          }).catch(() => {

          });
    }else {
      next()
    }
    }else {
      alert('错误')
    }
})
export default router
