import { defineStore } from 'pinia'

export const useIndexStore = defineStore('index', {
    state: () => {
        return {
            title: "主页",
            value: "主页",
            editType: false
        }
    },
})
